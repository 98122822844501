<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <div class="report-title">
            <h4 class="font-weight-semibold">{{ survey.title }}</h4>
            <div class="survey-status">
                <span :class="`status status-${survey.status}`">
                    {{survey.status}}
                </span>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card">
                    <h6>Questions</h6>
                    <p class="large-number">{{ survey.questions_count }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card">
                    <h6>Views</h6>
                    <p class="large-number">{{ survey.views }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card">
                    <h6>Responses</h6>
                    <p class="large-number">{{ survey.respondents_count }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card">
                    <h6>Response Rate</h6>
                    <p class="large-number">{{ survey.responseRate }}%</p>
                </div>
            </div>
        </div>

        <div v-for="qn in questions" :key="qn.id">

            <div v-if="qn.report.length">

                <p class="question">{{ qn.title }}</p>

                <div class="question-stat">
                    <div class="question-stat-actions">
                        <ul>
                            <li><a @click.prevent href="#" title="Progress bar"><font-awesome-icon icon="list" /></a></li>
                            <li><a @click.prevent href="#" title="Pie chart"><font-awesome-icon icon="chart-pie" /></a></li>
                            <li><a @click.prevent href="#" title="Download as CSV"><font-awesome-icon icon="download" /></a></li>
                        </ul>
                    </div>                
                    <div v-for="record in keys(qn.report)" :key="record.key">
                        <div v-if="record.count !== 0" class="progress-container">
                            <div class="percentage">{{ record.percentage }}%</div>
                            <b-progress max="100">
                                <b-progress-bar 
                                    :precision="2" 
                                    show-progress 
                                    :value="record.percentage" 
                                    :label="record.key"
                                    :class="
                                        record.percentage <= 20 && record.percentage < 30 ? 'r-20' :
                                        record.percentage >= 30 && record.percentage < 50 ? 'r-30' :
                                        record.percentage >= 50 && record.percentage < 80 ? 'r-50' :
                                        ''
                                    "
                                >
                                </b-progress-bar>
                            </b-progress>
                            <div class="count">{{ record.count }}</div>
                        </div>
                    </div>              
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Survey",
    props: ['code'],
    data() {
        return {
            survey: {},
            questions: {}
        }
    },
    mounted() {
        this.loadSurvey()
        this.loadReport()
    },
    methods: {
        keys(obj) {
            return Object.values(obj)
        },
        loadSurvey() {
            this.$http.get("/api/reports/survey/" + this.code).then(response => {
                this.survey = response.data.data
            }).catch(error => {
                this.showError(error)
            });
        },
        loadReport() {
            this.$http.get("/api/reports/" + this.code).then(response => {
                this.questions = response.data.data
              console.log(this.questions.map((a) => a.report))
            }).catch(error => {
                this.showError(error)
            });
        }
    }
}
</script>

<style scoped>
</style>